/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl
} from 'react-leaflet';
import { useMounted } from 'utils/hooks/useMounted';
import { divIcon, Point } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';
import { Branch } from './components/Branch';
import { BranchIcon } from './components/BranchIcon';
import { BranchPopup } from './components/BranchPopup';

const Locations = [
  {
    id: 'Q6',
    desc: 'Đầu đường là Bệnh Viện Quận 6, cổng đường Chợ Lớn',
    title: 'Cơ sở Quận 6',
    images: [
      'toMRYTgsRP2BEZkY7W03',
      'EzxetXiQYid77fwaVdWx',
      'weLwmYXATlyR70NRN4Nz',
      'j8yo7W3yQ2S3ZWfMYMTO',
      'OAM2XadRSGus7JDHEKb1',
      '6prqJbHcSgOd802XlvUN',
      'PLR1HOomSzC0s5YEVrRY',
      'Z8jeKZHSSCe2GsfBvy0A',
      '1W6fyLbtTe2I5Apy3ar2',
      'fRre9nUzQBuH7w6rpmWf',
      'Us9TBjgTFCsM99GEFada'
    ],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d489.9815845889407!2d106.6338926!3d10.7458355!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752faacd1bf259%3A0x345ae8465b2ce6c8!2zVHJ1bmcgdMOibSBsdXnhu4duIHRoaSBJRUxUUyBET0wgRW5nbGlzaCAtIMSQw6xuaCBM4buxYyBRdeG6rW4gNg!5e0!3m2!1sen!2s!4v1681878925175!5m2!1sen!2s',
    location: '16 đường số 3, P.11, Quận 6.',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.quan6.hcm',
    region: 'HCM',
    lat: 10.745800983386683,
    lng: 106.63400996177285,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/1f8e4f11-1591-47c9-96a4-bd2515619711.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+qu%E1%BA%ADn+6/@10.7457499,106.6339154,21z/data=!4m6!3m5!1s0x31752faacd1bf259:0x345ae8465b2ce6c8!8m2!3d10.745801!4d106.63401!16s%2Fg%2F11sd7wt_5d?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'Q7',
    title: 'Cơ sở Quận 7',
    images: [
      '9O6U2R1Rr6iBOs8JODKR',
      'vWlfGM7uREqHlu26fQcN',
      'VGStpwKVRCywHBSQPifQ',
      'q2z3VDPqQbRGF79wyDbv'
    ],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12217.090706563724!2d106.70849623727011!3d10.744387705524634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f2273ea9173%3A0xfd85d8dfa5cc0798!2zVHJ1bmcgdMOibSBsdXnhu4duIHRoaSBJRUxUUyBET0wgxJDDrG5oIEzhu7FjIHF14bqtbiA3!5e0!3m2!1svi!2s!4v1708425417868!5m2!1svi!2s',
    location: '456 Nguyễn Thị Thập, P.Tân Quy, Q.7, TP.HCM',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.q7.hcm',
    region: 'HCM',
    lat: 10.739456425113058,
    lng: 106.70747044114866,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/b94b40e9-0aca-4002-afef-c3450a1fca0f.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+qu%E1%BA%ADn+7/@10.7394722,106.7028678,17z/data=!3m1!4b1!4m6!3m5!1s0x31752f9c625aa46d:0xcb76e03507244138!8m2!3d10.739467!4d106.7074812!16s%2Fg%2F11vsyq8l7n?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: '3/2',
    desc: 'Ngay ngã tư 3/2 và Nguyễn Tri Phương. Đầu đường là BIDV và siêu thị Satra Mart.',
    title: 'Cơ sở Quận 10',
    images: [
      'oPFXfrvVTBmOLWJNkJdi',
      'plEyz3MS0aDIKZdm4FRw',
      'VFCgze6OS06qFs3cboqs',
      'hnvuKATMTrCrnUkKNh80',
      'C2fDHtBSfm5z5ilXhCSP',
      'sDkeAbTimAaOo2XEuvkA',
      'ThwoK0gvQjKcoHNocZFy',
      'brOJX8eJQjyQfRlJcC5h'
    ],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.550754540823!2d106.66787830000001!3d10.7690646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752fa0c8f8a1b9%3A0x9db957debb7acb62!2sDOL%20English!5e0!3m2!1sen!2s!4v1708426773340!5m2!1sen!2s',
    location: 'Hẻm 458/14 đường 3/2, P.12, Quận 10.',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.ieltsdinhluc',
    region: 'HCM',
    lat: 10.769065379718926,
    lng: 106.66787862087456,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/b23f9ba9-b4ae-4ebd-9910-698334cc84a6.PNG',
    viewMapLink:
      'https://www.google.com/maps/place/DOL+English/@10.7690699,106.6653034,17z/data=!3m1!4b1!4m6!3m5!1s0x31752fa0c8f8a1b9:0x9db957debb7acb62!8m2!3d10.7690646!4d106.6678783!16s%2Fg%2F11hyxt33rj?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'PN',
    desc: '',
    title: 'Cơ sở Phú Nhuận',
    images: [
      'RTpEfApYTgWDnW9uA2Cd',
      '7x410b7JRWay9puWnIDc',
      'bcMPurvzTpCz3qbdiuh7',
      '0Y1xNkgQUau4vcCU35yw',
      'IJaJaIklTbisrwnMiboQ',
      '6aUoEsguSaujJlDExZgc',
      'dcVolphQhaJw9lmdVK0r',
      'fI5tTAa7R6m84ev3bBzh',
      'iycbj7MORVMKNyopKhtU',
      'PKPSd0vURHGnbTsTk3WG'
    ],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15676.914152058978!2d106.6797262!3d10.7938006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175290f9b4a7f05%3A0x2525d1d9ceb2afa7!2zRE9MIEVuZ2xpc2ggLSBJRUxUUyDEkMOsbmggTOG7sWMgcXXhuq1uIFBow7ogTmh14bqtbiBIQ00!5e0!3m2!1svi!2s!4v1696299591066!5m2!1svi!2s',
    location: '38/6 Nguyễn Văn Trỗi, Phường 15, Quận Phú Nhuận, TPHCM',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.ieltsdinhluc',
    region: 'HCM',
    lat: 10.793836854149262,
    lng: 106.67966888717588,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/00ff2066-24b2-4e4f-80dd-bb4a7ababc20.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+qu%E1%BA%ADn+Ph%C3%BA+Nhu%E1%BA%ADn/@10.7936129,106.6793202,18z/data=!4m9!1m2!2m1!1zMzgvNiDEkMaw4budbmcgTmd1eeG7hW4gVsSDbiBUcuG7l2ksIHBoxrDhu51uZyAxNSwgUXXhuq1uIFBow7ogTmh14bqtbiwgSG8gQ2hpIE1pbmggQ2l0eQ!3m5!1s0x3175290f9b4a7f05:0x2525d1d9ceb2afa7!8m2!3d10.7938006!4d106.6797262!16s%2Fg%2F11vbx4_fl3?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'BTD3',
    desc: '',
    title: 'Cơ sở Bình Thạnh - D3',
    images: ['GySCaDcuR9KGGjv2ZNIL'],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62705.66462021851!2d106.71934309999999!3d10.8033432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529a4ece8aa3b%3A0xc44b511908176fed!2zRE9MIEVuZ2xpc2ggLSBJRUxUUyDEkMOsbmggTOG7sWMgVsO1IE9hbmggQsOsbmggVGjhuqFuaCBIQ00!5e0!3m2!1sen!2sus!4v1695095339022!5m2!1sen!2sus',
    location: '24/1 Võ Oanh (D3), Bình Thạnh, P.25, TP.HCM',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.binhthanh',
    region: 'HCM',
    lat: 10.803697261724233,
    lng: 106.7193062716335,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/77a23673-fa5d-47b5-bb06-d4af5d462f3e.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+V%C3%B5+Oanh+Q.+B%C3%ACnh+Th%E1%BA%A1nh/@10.8036608,106.7192448,19.07z/data=!4m6!3m5!1s0x317529a4ece8aa3b:0xc44b511908176fed!8m2!3d10.8036987!4d106.7192931!16s%2Fg%2F11ssk3qjrg?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'BT',
    desc: 'Gần ngã ba Nguyễn Văn Đậu giao Phan Văn Trị. Chung tòa nhà với Trăng Non rooftop.',
    title: 'Cơ sở Bình Thạnh - Nguyễn Văn Đậu',
    images: [
      'GZRGOebSMiWdhuXj58FT',
      '62CSAjPPQoedCTnAn0qx',
      'ij4QC1AvRcSRS9zIVwWS',
      'zQPDQhjQRXqWMEVVKSAC',
      'pgcUIXZLRZWxaQ5XBvUj'
    ],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3918.9541070562436!2d106.6922195!3d10.8148241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175291b4258aedb%3A0x77d95238058286b7!2zRG9sIEllbHRzIMSQw6xuaCBM4buxYw!5e0!3m2!1sen!2s!4v1669093990289!5m2!1sen!2s',
    location: '302 Nguyễn Văn Đậu, P.11, Q. Bình Thạnh.',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.binhthanh',
    region: 'HCM',
    lat: 10.814765630615627,
    lng: 106.6944150125122,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/6dcc4164-3caa-4da0-832b-257893db3d10.PNG',
    viewMapLink:
      'https://www.google.com/maps/place/Dol+Ielts+%C4%90%C3%ACnh+L%E1%BB%B1c/@10.8146973,106.6943778,19.96z/data=!4m14!1m7!3m6!1s0x3175291df8199eef:0xf3263cc43447687!2zUGFzc2lvIENvZmZlZSAzMDIgTmd1eeG7hW4gVsSDbiDEkOG6rXU!8m2!3d10.8147294!4d106.694397!16s%2Fg%2F11jdy6pqjx!3m5!1s0x3175291b4258aedb:0x77d95238058286b7!8m2!3d10.8147676!4d106.6944158!16s%2Fg%2F11jsdb5pcp?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'TB',
    desc: '',
    title: 'Cơ sở Tân Bình',
    images: ['hjKDbS2CSzmVGuWZ1pOg'],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15676.88812687693!2d106.640801!3d10.7942995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529cc15ecf14f%3A0x3513e26f7dece5b5!2zVHJ1bmcgdMOibSBsdXnhu4duIHRoaSBJRUxUUyBEb2wgRW5naGxpc2ggLSDEkMOsbmggTOG7sWMgQsOgdSBDw6F0IDIgUS5Uw6JuIELDrG5o!5e0!3m2!1svi!2s!4v1681879101335!5m2!1svi!2s',
    location: '24A Bàu Cát 2, Tân Bình, P.14, TP.HCM',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.tanbinh.hcm',
    region: 'HCM',
    lat: 10.794044511352205,
    lng: 106.641641294771,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/868c2f9e-b6c4-4b65-8d3a-4b59d1467f6a.PNG',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+qu%E1%BA%ADn+T%C3%A2n+B%C3%ACnh/@10.7940709,106.6390664,17z/data=!3m1!4b1!4m6!3m5!1s0x317529cc15ecf14f:0x3513e26f7dece5b5!8m2!3d10.7940656!4d106.6416413!16s%2Fg%2F11ty24bf0l?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'GV',
    desc: 'Đối diện Lotte Mart.',
    title: 'Cơ sở Gò Vấp',
    images: [
      'Pe1mYAOYSWi0B4fRvIR4',
      '5QVra2GaSiyoNzvrDHJd',
      'mTsnsVPLRay4vEaHlNG1'
    ],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31353.000694229388!2d106.6517214!3d10.8017305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529a89bce360b%3A0x9a8406693fc0b77f!2zRE9MIEVuZ2xpc2ggLSBJRUxUUyDEkMOsbmggTOG7sWMgcXXhuq1uIEfDsiBW4bqlcCBIQ00!5e0!3m2!1sen!2s!4v1693368695446!5m2!1sen!2s',
    location: '95-97 Đường số 3, Khu Cityland Park Hills, P10, Gò Vấp',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.govap',
    region: 'HCM',
    lat: 10.838062396064345,
    lng: 106.67028539273565,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/37047ac6-2aaf-48b0-aca6-829e55d49051.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+qu%E1%BA%ADn+G%C3%B2+V%E1%BA%A5p/@10.8378174,106.6697114,18z/data=!4m14!1m7!3m6!1s0x317529a465c9c3f7:0xbcf13c3f5464c72!2sIvyPrep+Cityland+Park+Hills!8m2!3d10.8380879!4d106.6703649!16s%2Fg%2F11p3g_wpy1!3m5!1s0x317529a89bce360b:0x9a8406693fc0b77f!8m2!3d10.8380723!4d106.6702926!16s%2Fg%2F11sb8r_g8g?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'Q9',
    desc: 'Sát Vincom Plaza Lê Văn Việt.',
    title: 'Cơ sở Quận 9 - Lê Văn Việt',
    images: ['ZEo6K3QKSSW97sL9NXbz'],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15674.228871411458!2d106.7705144!3d10.8451576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752707650f4fe5%3A0xc10b22b24eb65d77!2zRE9MIEVuZ2xpc2ggLSBUaOG7pyDEkOG7qWMgLSBMw6ogVsSDbiBWaeG7h3Q!5e0!3m2!1sen!2s!4v1681879463674!5m2!1sen!2s',
    location: 'Lầu 4 Tòa nhà MB, 25B Lê Văn Việt, P. Hiệp Phú, TP Thủ Đức.',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.thuduc',
    region: 'HCM',
    lat: 10.845346650694346,
    lng: 106.77936934960056,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/e14777fe-03b5-4aad-9f71-06c2a479afd8.PNG',
    viewMapLink:
      'https://www.google.com/maps/place/DOL+English+-+Th%E1%BB%A7+%C4%90%E1%BB%A9c+-+L%C3%AA+V%C4%83n+Vi%E1%BB%87t/@10.8451775,106.7793022,19.6z/data=!4m9!1m2!2m1!1zVMOyYSBuaMOgIE1CLCAyNUIgTMOqIFbEg24gVmnhu4d0LCBQLiBIaeG7h3AgUGjDuiwgVFAgVGjhu6cgxJDhu6lj!3m5!1s0x31752707650f4fe5:0xc10b22b24eb65d77!8m2!3d10.8452894!4d106.7793322!16s%2Fg%2F11sc0c6qgd?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'TD2',
    desc: '',
    title: 'Cơ sở Thủ Đức - Tô Vĩnh Diện',
    images: ['NRmH2bZRoGYmB27NvOBA'],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.90356169161706!2d106.76696240022999!3d10.852804802783037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175279f0f908d41%3A0x199f2b54cf616d3a!2zOSBUw7QgVsSpbmggRGnhu4duLCBMaW5oIENoaeG7g3UsIFRo4bunIMSQ4bupYywgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1703060097212!5m2!1sen!2s',
    location: '9A Tô Vĩnh Diện, P.Linh Chiểu, TP Thủ Đức',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.thuduc',
    region: 'HCM',
    lat: 10.852685999826658,
    lng: 106.76699718188839,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/d9dcd1e2-ebcf-4617-83c7-943de354e359.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/DOL+IELTS+%C4%90%C3%8CNH+L%E1%BB%B0C+TH%E1%BB%A6+%C4%90%E1%BB%A8C/@10.852553,106.7667719,19z/data=!4m6!3m5!1s0x3175270054c4f53f:0x2da247cc6aa211e5!8m2!3d10.85269!4d106.7669972!16s%2Fg%2F11vk50sjst?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'HN',
    desc: '',
    title: 'Cơ sở Thanh Xuân - Hà Nội',
    images: ['c3NRc2VPRKskZkLQ9vZt'],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14899.965994080803!2d105.807884!3d20.9929779!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135adb6bfbf533b%3A0x54c7e30fbe81fb1e!2zVHJ1bmcgdMOibSBsdXnhu4duIHRoaSBJRUxUUyBEb2wgRW5nbGlzaCAtIMSQw6xuaCBM4buxYyBIw6AgTuG7mWk!5e0!3m2!1svi!2s!4v1681878991910!5m2!1svi!2s',
    location: 'Lầu 2, Tòa nhà Gold Tower, 275 Nguyễn Trãi, Q. Thanh Xuân, HN',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.hanoi',
    region: 'HN',
    lat: 20.99300866285921,
    lng: 105.8079493264643,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/bbcb9967-4edf-4ec7-a7aa-38d066964ca8.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+qu%E1%BA%ADn+Thanh+Xu%C3%A2n/@20.992911,105.8074558,18z/data=!4m6!3m5!1s0x3135adb6bfbf533b:0x54c7e30fbe81fb1e!8m2!3d20.9929779!4d105.807884!16s%2Fg%2F11kjjgl0t5?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'HN.DD',
    desc: '',
    title: 'Cơ sở Đống Đa - Hà Nội',
    images: ['vT4MVE0ZSl60CRmCUqyD'],
    mapLink: 'https://maps.app.goo.gl/UTgpsZHbfBdUTHTk8',
    location: 'Tầng G, số 158 Phố Chùa Láng, P.Láng Thượng, Q.Đống Đa, Hà Nội',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.dongda',
    region: 'HN',
    lat: 21.022795101152287,
    lng: 105.80060241227291,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/88199add-a241-4c64-8652-1c289cb60b04.PNG',
    viewMapLink:
      'https://www.google.com/maps/place/DOL+English+-+IELTS+%C4%90%C3%ACnh+L%E1%BB%B1c+-+%C4%90%E1%BB%91ng+%C4%90a+-+158+Ch%C3%B9a+L%C3%A1ng/@21.0227677,105.8004094,19.68z/data=!4m6!3m5!1s0x3135ab4b44dcccfd:0x7246103a593452a5!8m2!3d21.0227956!4d105.8006024!16s%2Fg%2F11wj9_t1lt?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  },
  {
    id: 'DN',
    desc: '',
    title: 'Cơ sở Đà Nẵng',
    images: ['m94hD757RG2Ljegvlt03', '4xqhWPwYQXWxuWxGplV9'],
    mapLink:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d958.5234536978913!2d108.2192635!3d16.0606208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142197654c11507%3A0xdc908328a898cf76!2zRE9MIEVuZ2xpc2ggLSBJRUxUUyDEkMOsbmggTOG7sWMgUXXhuq1uIEjhuqNpIENow6J1IMSQw6AgTuG6tW5n!5e0!3m2!1sen!2s!4v1695095457443!5m2!1sen!2s',
    location: 'Lầu 3, Thư Dung Plaza, 87 Nguyễn Văn Linh, Q.Hải Châu, Đà Nẵng',
    imgHandle: 'FCzMMSeST6OzB0Fi8uYZ',
    linkContact: 'https://m.me/dolenglish.danang',
    region: 'DN',
    lat: 16.060689157856533,
    lng: 108.21926149440866,
    image:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/920e9695-1a07-4857-8ea6-72677b2c228b.jpg',
    viewMapLink:
      'https://www.google.com/maps/place/Trung+t%C3%A2m+luy%E1%BB%87n+thi+IELTS+DOL+%C4%90%C3%ACnh+L%E1%BB%B1c+%C4%90%C3%A0+N%E1%BA%B5ng/@16.0605179,108.2187834,18.89z/data=!4m6!3m5!1s0x3142197654c11507:0xdc908328a898cf76!8m2!3d16.0606208!4d108.2192635!16s%2Fg%2F11txtf94fn?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D'
  }
];

const Container = styled.div`
  margin-top: 32px;
  .intro {
    margin-top: 12px;
  }
  ${fromScreen(776)} {
    margin-top: 100px;
  }
`;

export const BranchLocationV3 = ({ title, desc }) => {
  const mounted = useMounted();

  return (
    <Container>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
      >
        {title}
      </Typography>
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        v3
        className="intro"
      >
        {desc}
      </Typography>
      {mounted && <ListOffice branchList={Locations} />}
    </Container>
  );
};

const Main = styled.div``;
const TabBar = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 8px;
  margin: 24px 0;
  padding: 12px 0;
  width: fit-content;
`;
const TabBarContent = styled.div`
  padding: 10px 12px;
  background: transparent;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
  cursor: pointer;
  &.isSelected {
    background: ${colorsV2.primary10};
    border: 1px solid ${colorsV2.primary10};
    > p {
    color: ${colorsV2.primary100};
  }
`;

const MainList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  .map-container {
    display: none;
    height: 478px;
    border-radius: 16px;
    z-index: 50;
  }

  .map-branch {
    width: 52px !important;
    height: 52px !important;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .map-popup {
    bottom: 10px !important;
    left: -26px !important;

    .leaflet-popup-tip-container {
      display: none;
    }
    .leaflet-popup-content {
      max-width: 290px !important;
      margin: 0;
      padding: 16px;
      p {
        margin: 0;
      }
    }
  }
  ${fromScreen(776)} {
    .map-container {
      display: block;
    }
  }
  ${fromScreen(1144)} {
    grid-template-columns: repeat(2, 1fr);
    .map-container {
      height: 792px;
    }
  }
`;

const Branches = styled.div`
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const ListOffice = ({ branchList }) => {
  const hcmBranches = branchList?.filter(x => x.region === 'HCM');
  const hnBranches = branchList?.filter(x => x.region === 'HN');
  const dnBranches = branchList?.filter(x => x.region === 'DN');
  const regions = [
    {
      id: 'HCM',
      name: 'Hồ Chí Minh',
      branchs: hcmBranches,
      center: [10.769065379718926, 106.66787862087456]
    },
    {
      id: 'HN',
      name: 'Hà Nội',
      branchs: hnBranches,
      center: [21.022795101152287, 105.80060241227291]
    },
    {
      id: 'DN',
      name: 'Đà Nẵng',
      branchs: dnBranches,
      center: [16.060689157856533, 108.21926149440866]
    }
  ];

  const [regionSelected, setRegionSelected] = React.useState(regions[0]);
  const [selectedBranchId, setSelectedBranchId] = React.useState('');
  const handleChangeRegion = id => {
    const findRegion = regions?.find(x => x.id === id);
    if (findRegion) {
      setRegionSelected(findRegion);
    }
  };

  return (
    <Main>
      <TabBar>
        {regions?.map((eachRegion, idx) => (
          <TabBarContent
            key={idx}
            className={cl({ isSelected: regionSelected.id === eachRegion.id })}
            onClick={() => handleChangeRegion(eachRegion?.id)}
          >
            <Typography variant="medium/14-20" color={colorsV2.neutralPrimary}>
              {eachRegion?.name}
            </Typography>
          </TabBarContent>
        ))}
      </TabBar>
      <MainList>
        <MapContainer
          key={`${regionSelected?.center}`}
          center={regionSelected?.center}
          zoom={12}
          className="map-container"
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {regionSelected &&
            regionSelected?.branchs?.map((eachBranch, idx) => (
              <Marker
                key={idx}
                position={{ lat: eachBranch?.lat, lng: eachBranch?.lng }}
                icon={divIcon({
                  html: renderToString(
                    <BranchIcon
                      isActive={eachBranch.id === selectedBranchId}
                      image={eachBranch?.image}
                    />
                  ),
                  className: 'custom-icon',
                  iconSize: new Point(52, 52)
                })}
                eventHandlers={{
                  popupopen: () => {
                    setSelectedBranchId(eachBranch?.id);
                  },
                  popupclose: () => {
                    setSelectedBranchId('');
                  }
                }}
              >
                <Popup className="map-popup" closeButton={false}>
                  <BranchPopup
                    title={eachBranch?.title}
                    location={eachBranch?.location}
                    viewMapLink={eachBranch?.viewMapLink}
                  />
                </Popup>
              </Marker>
            ))}
          <ZoomControl position="bottomright" />
        </MapContainer>
        <Branches>
          {regionSelected &&
            regionSelected?.branchs?.map((eachBranch, idx) => (
              <Branch
                key={idx}
                title={eachBranch?.title}
                image={eachBranch?.image}
                location={eachBranch?.location}
                viewMapLink={eachBranch?.viewMapLink}
              />
            ))}
        </Branches>
      </MainList>
    </Main>
  );
};
