import * as React from 'react';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import PageBanner from 'shared/Center/CenterHeader';
import { withHomePageSection } from 'components/withHomePageSection';
import { BranchLocationV3 } from 'shared/Center/BranchLocationV3';
import { OfficeImage } from 'shared/Center/OfficeImage';
import MySEO from '../shared/MySEO';

const SectionContent = withHomePageSection(() => (
  <>
    <BranchLocationV3
      title="Các cơ sở của DOL"
      desc="Để đem Linearthinking đến gần các bạn hơn, hiện tại DOL có các cơ sở
        sau:"
    />
    <OfficeImage />
  </>
));

const Page = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        h1="Hệ thống cơ sở của DOL"
        title="Hệ thống cơ sở - DOL English"
        description="Hệ thống cơ sở của DOL - IELTS Đình Lực bao gồm các cơ sở tại 3 quận ở TPHCM là Quận 10, Quận Bình Thạnh và Quận Tân Bình cùng nền tảng học online trên cả nước."
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <PageBanner
        title={`<span class="highlight">Hệ thống cơ sở</span><br/> của DOL`}
        description="Hiện DOL đang có mặt ở hơn 10 quận tại TP.HCM, 3 cơ sở ở Hà Nội cùng 1 cơ sở ở Đà Nẵng và nền tảng Online Zoom."
      />
      <SectionContent />
    </MainLayout>
  );
};

export default Page;
