/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { IconNavigationPin } from 'components/Icon/SVGIcons/IconNavigationPin';
import { colorsV2 } from 'style/colors-v2';

const BranchContent = styled.div`
  display: grid;
  grid-template-columns: 56px 1fr;
  gap: 12px;
`;

const MapBranch = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  border: 4px solid;
  border-color: white;
  border-radius: 14px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

const Content = styled.div`
  display: grid;
  gap: 12px;
`;

const PopupDescription = styled.div`
  display: grid;
  gap: 8px;
`;

const Icon = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 4px;
  &.view-map {
    text-decoration: underline;
    text-decoration-color: ${colorsV2.blue100};
    > a {
      cursor: pointer;
    }
  }
`;

export const Branch = ({ image, title, location, viewMapLink }) => {
  return (
    <BranchContent>
      <MapBranch>
        <img src={image} alt="map-brand" />
      </MapBranch>
      <Content>
        <Typography variant="semi-bold/16-24" color={colorsV2.black100}>
          {title}
        </Typography>
        <PopupDescription>
          <Icon>
            <IconNavigationPin
              width={20}
              height={20}
              color={colorsV2.black60}
            />
            <Typography variant="regular/14-20" color={colorsV2.black60}>
              {location}
            </Typography>
          </Icon>
          <Icon className="view-map">
            <div></div>
            <a href={viewMapLink} target="_blank" rel="noreferrer noopener">
              <Typography variant="regular/14-20" color={colorsV2.blue100}>
                View Map
              </Typography>
            </a>
          </Icon>
        </PopupDescription>
      </Content>
    </BranchContent>
  );
};
